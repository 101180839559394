import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/style.css"
import PageTemplate from "../components/pagetemplate"
import Helmet from "react-helmet"

const IndexPage = ({data}) => {
    const { markdownRemark: page } = data

    return (
        <div className="page homepage">
            <div className="double-bg"></div>
            <div className="bg homepage-bg"></div>
              <Helmet title={data.site.siteMetadata.title}>
                <link rel="stylesheet" href="https://use.typekit.net/bjf1uex.css" />
              </Helmet>
            <Header siteTitle={data.site.siteMetadata.title}/>
            <main>
              <PageTemplate 
                  title={page.frontmatter.title}
                  content={page.html}
                  cols="9"
              />
            </main>
            <Footer />
        </div>
    )
}

export default IndexPage

export const pageQuery = graphql`
query IndexPageTemplate {
  markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
    html
    frontmatter {
      title
      thumbnail
    }
  }
  site {
    siteMetadata {
        title
    }
}
}
`